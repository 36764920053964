.page-404 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;
  padding-left: 140px;

  @media (max-width: 1024px) {
    & {
      padding-top: 80px;
      padding-bottom: 80px;
      padding-left: 80px;
      grid-template-columns: 100%;
      min-height: 100vh;
      height: auto;
    }
  }

  @media (max-width: 696px) {
    & {
      padding-left: 0;
    }
  }
}

.page-404_details {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spaceXL);
  height: 100%;
}

.page-404_image {
  display: flex;
  align-items: center;
  justify-content: left;
}

.page-404_text {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
}

.page-404_title {
  margin-bottom: var(--spaceM);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: 0.1s;
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404_title--entered {
  transform: none;
  opacity: 1;
}

.page-404_subheading {
  padding-bottom: var(--spaceL);
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: rgb(var(--rgbText) / 0.4);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: 0.2s;
  opacity: 0;
  max-width: 100%;
  white-space: nowrap;
  flex: 0 0 auto;

  @media (max-width: 696px) {
    & {
      font-size: calc((18 / 16) * 1rem);
    }
  }

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404_subheading--entered {
  transform: none;
  opacity: 1;
}

.page-404_description {
  padding-bottom: var(--spaceL);
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: 0.3s;
  opacity: 0;

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404_description--entered {
  transform: none;
  opacity: 1;
}

.page-404_button {
  transition-property: opacity;
  transition-timing-function: var(--bezierFastoutSlowin);
  transition-duration: var(--durationXL);
  transition-delay: var(--durationM);
  opacity: 0;
  align-self: flex-start;
  padding-left: var(--spaceXXS);

  @media (prefers-reduced-motion: no-preference) {
    & {
      transition-property: transform, opacity;
      transform: translate3d(0, var(--spaceL), 0);
    }
  }
}

.page-404_button--entered {
  transform: none;
  opacity: 1;
}
